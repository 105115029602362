<template>
  <v-dialog v-model="showAddressDialog" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ title }}</span>
      </v-card-title>
      <v-card-text>
        <AddressForm :errors="violations" :values="item" :loading="isLoading" :ref="getFormRef()"/>
      </v-card-text>
      <v-card-actions>
        <v-btn v-if="handleUpdated" @click="confirmDelete = true" small class="red" dark color="danger">{{
            $t('Delete')
          }}
        </v-btn>
        <v-btn text @click="showAddressDialog = false">{{ $t('Close') }}</v-btn>
        <v-spacer/>
        <v-btn class="mainAccent lighten-3" dark @click="resetForm">{{ $t('Reset') }}</v-btn>
        <v-btn color="primary" @click="sendForm">{{ $t('Submit') }}</v-btn>
      </v-card-actions>
      <ConfirmDelete
        :handle-delete="del"
        :visible="confirmDelete"
        @close="confirmDelete = false"
        v-if="handleUpdated"
      />
    </v-card>
    <Loading :visible="isLoading"/>
  </v-dialog>
</template>

<script>
import {createHelpers} from 'vuex-map-fields';
import AddressForm from './Form';
import NotificationMixin from '../../mixins/NotificationMixin';
import ConfirmDelete from '../ConfirmDelete';
import Loading from "@/components/Loading";
import {mapActions, mapGetters} from "vuex";

const servicePrefix = 'Address';

const {mapFields} = createHelpers({
  getterType: 'address/getField',
  mutationType: 'address/updateField'
});

export default {
  name: "AddressDialog",
  servicePrefix,
  mixins: [NotificationMixin],
  components: {
    Loading,
    AddressForm,
    ConfirmDelete
  },
  props: {
    value: Array,
    title: {
      type: String,
      required: true
    },
    handleCreated: {
      type: Function,
      required: false
    },
    handleUpdated: {
      type: Function,
      required: false
    },
    handleDeleted: {
      type: Function,
      required: false
    },
    addressItem: {
      type: Object,
      required: false,
    }
  },
  data() {
    return {
      item: {},
      confirmDelete: false,
      createMessage: this.$t("successfullyCreated"),
      updateMessage: this.$t("successfullyUpdated"),
      deleteMessage: this.$t("successfullyDeleted"),
    }
  },
  computed: {
    ...mapFields(['error', 'isLoading', 'created', 'deleted', 'violations', 'updated', 'byId']),
    ...mapGetters('address', ['find']),
    formRefName() {
      if (this.handleUpdated) {
        return "updateForm"
      }
      return "createForm";
    },
    formRef() {
      if (this.handleUpdated) {
        return this.$refs.updateForm;
      }
      return this.$refs.createForm;
    },
    showAddressDialog: {
      get() {
        return (this.value.indexOf(((typeof this.addressItem.id === "undefined") ? 'new' : this.addressItem.id)) > -1);
      },
      set(value) {
        if (!value) {
          this.$emit('close')
        }
      }
    },
  },
  methods: {
    ...mapActions('address', {
      create: 'create',
      update: 'update',
      resetData: 'resetData',
      retrieve: 'load',
      deleteItem: 'del',
    }),
    sendForm() {
      if (this.isValidForm()) {
        let model = this.formRef.$v.item.$model;
        if (this.handleUpdated) {
          this.resetForm();
          this.update(model);
        }
        if (this.handleCreated) {
          this.resetForm();
          this.create(model);
        }
      }
    },
    reset() {
      this.formRef.$v.$reset();
    },
    resetForm() {
      this.item = {...this.addressItem};
      this.formRef.$v.$reset();
    },
    del() {
      this.deleteItem(this.addressItem).then(() => {
        this.showAddressDialog = false;
        this.showMessage(this.deleteMessage);
      });
    },
    isValidForm() {
      this.formRef.$v.$touch();
      return !this.formRef.$v.$invalid;
    },
    getFormRef() {
      return this.formRefName;
    },
  },
  watch: {
    created(created) {
      if (!created) {
        return;
      }
      this.showMessage(this.createMessage);
      if (this.handleCreated) {
        this.handleCreated(this.created);
        this.showAddressDialog = false;
      }
    },
    updated() {
      this.showMessage(this.updateMessage);
      if (this.handleUpdated) {
        this.handleUpdated(this.updated);
        this.showAddressDialog = false
      }
    },
    deleted(){
      if (this.handleDeleted) {
        this.handleDeleted(this.deleted);
      }
    },
    error(message) {
      message && this.showError(message);
    },
    deleteError(message) {
      message && this.showError(message);
    },
  },
  created() {
    this.item = {...this.addressItem};
  }
}
</script>
