<template>
  <v-row>
    <v-col col="12" md="6" v-if="this.defaultObjects.length > 0">
      <h3>{{ $t('DefaultAddresses') }}</h3>
      <AddressCard v-model="defaultObjects"/>
    </v-col>
    <v-col col="12" md="6" v-if="this.businessObjects.length > 0">
      <h3>{{ $t('BusinessAddresses') }}</h3>
      <AddressCard v-model="businessObjects"/>
    </v-col>
    <v-col col="12" md="6" v-if="this.privateObjects.length > 0">
      <h3>{{ $t('PrivateAddresses') }}</h3>
      <AddressCard v-model="privateObjects"/>
    </v-col>
    <v-col col="12" md="6" v-if="this.invoiceObjects.length > 0">
      <h3>{{ $t('InvoiceAddresses') }}</h3>
      <AddressCard v-model="invoiceObjects"/>
    </v-col>
    <v-col col="12" md="6" v-if="this.deliveryObjects.length > 0">
      <h3>{{ $t('DeliveryAddresses') }}</h3>
      <AddressCard v-model="deliveryObjects"/>
    </v-col>
  </v-row>
</template>

<script>
import AddressCard from '../../components/address/AddressCard';

export default {
  name: 'AddressList',
  components: {
    AddressCard
  },
  props: {
    addressObjects: {
      type: Array,
      required: true
    },
  },
  computed: {
    defaultObjects() {
      return this.addressObjects.filter((address) => address && (address.type === 'default') && address);
    },
    businessObjects() {
      return this.addressObjects.filter((address) => address && (address.type === 'business') && address);
    },
    invoiceObjects() {
      return this.addressObjects.filter((address) => address && (address.type === 'invoice') && address);
    },
    deliveryObjects() {
      return this.addressObjects.filter((address) => address && (address.type === 'delivery') && address);
    },
    privateObjects() {
      return this.addressObjects.filter((address) => address && (address.type === 'private') && address);
    },
  }
};
</script>
